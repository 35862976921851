import { request } from "./network";

// 人脸识别
export function promise(zkz,subjectUuid,url) {
    return request({
        method: "post",
        url: '/chengnuoshu/'+ subjectUuid +'/'+ zkz,
        data: {"url" : url}
    });
}
