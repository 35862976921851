<template>
    <div class="promise-container" ref="promise-container" :class="loadingFile?'no-scroll':''">
        <van-loading class="promise-loading" v-show="loadingFile" />
        <div class="promise_header">
            <van-nav-bar
                    title=""
                    left-text="取消"
                    right-text="提交"
                    @click-left="onCancel"
                    @click-right="onCommit"/>

        </div>
        <div class="promise_content" ref="promise_content">
            <div class="promise_tit">
                <div>2022年海淀区高中阶段艺术特长生</div>
                <div>诚信承诺书</div>
            </div>
            <div class="promise_txt">
                我是______中学（学校）初三年级毕业生_______，身份证号为______，中考报名号为______（录像时手持考生身份证或学生 CMIS 卡），北京时间 2022 年 5 月 28
                日星期日，我将要参加清华大学附属中学高中阶段艺术特长生测试。
            </div>
            <div class="promise_txt">
                我承诺遵守清华大学附属中学艺术特长生测试的各项要求，
                独立自主完成测试内容,不向外泄漏、公布测试内容及测试过程。
                如有违反测试要求的情况，我本人将承担一切后果。

            </div>
            <div class="promise_txt">
                <p style="text-align: right;padding-right: 10px">承诺人：______</p>
            </div>
            <!--<div class="promise_txt">
                监护人：我是承诺人的父亲/母亲 ，我对上述承诺无异议。<br/>
                <p style="text-align: right;padding-right: 10px">监护人：______</p>
            </div>-->
            <div class="promise_info">
                请面向手机监控朗读并在下方区域手写签名并勾选"我已阅读并自愿遵守本承诺书要求"。
                <!--请面向手机监控朗读并在下方区域手写签名并勾选"我已阅读并自愿遵守本承诺书要求"。-->
           </div>
           <div class="promise_sign" ref="promise_sign" id="pdfDom">
               <vue-esign ref="esign" :height="470" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
                          :bgColor.sync="bgColor" :isClearBgColor="false" @touchmove.native="touchMove"
                          @mousemove.native="mouseMove"/>
               <!-- <canvas
                       class="canvas"
                       @touchstart="touchStart"
                       @touchmove="touchMove"
                       @touchend="touchEnd"
                       ref="canvasF"
                       @mousedown="mouseDown"
                       @mousemove="mouseMove"
                       @mouseup="mouseUp"
               ></canvas> -->
                <div class="rewrite" @click="handleReset" v-if="isHide">重写</div>
            </div>
            <div class="promise_checked">
                <van-checkbox v-model="checked" @click="promiseChecked">我已阅读并自愿遵守本承诺书要求</van-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    import {promiseMixin} from "mixins/index/promise";

    export default {
        name: "promise",
        mixins: [promiseMixin],
    }
</script>

<style scoped lang="scss">
    .promise-container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: column;
        &.no-scroll{
            height: 100%;
            overflow: hidden;
        }
        /*overflow-y: scroll;*/
        .promise-loading{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            min-height: 100%;
            z-index: 1000;
            background-color: rgba(0,0,0,0.6);
            overflow: hidden;
            .van-loading__spinner{
                width: 50px;
                height: 50px;
                position: absolute;
                left: 45%;
                top: 45%;
                transform: translate(-50%, -50%);
                color: #563279;
            }
        }

        .promise_header {
            position: fixed;
            width: 100%;
            background-color: #ffffff;
            z-index: 10;

            ::v-deep .van-nav-bar {
                .van-nav-bar__left {
                    .van-nav-bar__text {
                        color: #ee0a24;
                    }
                }

                .van-nav-bar__right {
                    .van-nav-bar__text {
                        color: #8b2d87;
                    }
                }

            }
        }

        .esign {
            background: rgb(204, 204, 204);
        }

        .promise_content {
            flex: 1;
            width: 95%;
            margin: 48px auto 2%;
            border: 2px dashed #aaaaaa;
            padding: 0 10px 10px;
            background-color: #fff;
            font-family: "PingFang SC";

            .promise_tit {
                font-size: 16px;
                text-align: center;
                line-height: 24px;
                padding: 15px 0;
                font-weight: 500;
                font-family: "PingFangSC-Regular";
            }

            .van-loading-new{
                top:-40px;
                left:45%;
            }

            .promise_txt {
                font-size: 14px;
                text-align: left;
                line-height: 24px;
                text-indent: 2em;
                font-weight: 400;
                font-family: "PingFang SC";

                > span {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    font-family: "PingFangSC-Regular";
                }
            }

            .promise_info {
                font-size: 15px;
                text-align: left;
                line-height: 22px;
                margin: 15px 0 10px;
            }

            .promise_sign {
                position: relative;
                width: 100%;
                // height: 220px;
                border: 1px solid #9e9e9e;
                // .canvas{
                //     width: 100%;
                //     height: 100% !important;
                // }
                .rewrite {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    padding: 0 5px;
                    height: 24px;
                    line-height: 24px;
                    color: #37B2E2;
                    // background-color: #ffffff;
                    text-decoration: underline;
                    cursor: pointer;
                    font-size: 14px;
                }
            }

            .promise_checked {
                text-align: center;
                margin: 15px 0;

                ::v-deep .van-checkbox {
                    justify-content: center;

                    .van-checkbox__label {
                        font-size: 14px;
                    }
                    .van-checkbox__icon--checked .van-icon {
                        color: #fff;
                        background-color: #563279;
                        border-color: #563279;
                    }
                }
            }
        }
    }
</style>

