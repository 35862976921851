//人脸识别相关
import {Dialog, Toast,Notify } from 'vant';
import html2canvas from "html2canvas"
import {getSignature} from "r/index/index";
import {fileUpload} from "r/index/upload";
import {promise} from "r/index/promise";
import {studentStatus} from "r/index/studentStatus";

export const promiseMixin = {
    data() {
        return {
            checked: false,
            points: [],
            canvas: null,
            canvasTxt: null,
            startX: 0,
            startY: 0,
            moveY: 0,
            moveX: 0,
            endY: 0,
            endX: 0,
            w: null,
            h: null,
            isDown: false,
            color: "#000",
            isDraw: false, //签名标记
            lineWidth: 6,
            lineColor: '#000000',
            bgColor: '#ccc',
            resultImg: '',
            isCrop: false,
            isHide: true,
            touchMoveFont: false,
            submit: true,
            loadingFile: false
        };
    },
    created() {
        // let chengnuoshu = localStorage.getItem("monitor-chengnuoshu");
        // if(chengnuoshu == 1){
        //     this.$router.replace({path: '/position'});
        // }
    },
    mounted() {
        localStorage.setItem("studentStatusTime",0);
        let exam_uuid = localStorage.getItem("monitor-subjectUuId");
        let sfzNum = localStorage.getItem("monitor-zkz");
        this.studentStatusPost(sfzNum,exam_uuid);
        this.$refs.esign.reset();

        // this.canvas = this.$refs.canvasF;
        // this.canvas.height = this.canvas.offsetHeight;
        // this.canvas.width = this.canvas.offsetWidth;
        // this.canvasTxt = this.canvas.getContext("2d");
        // this.canvasTxt.strokeStyle = this.color;
        // this.canvasTxt.lineWidth = this.lineWidth;
        // window.addEventListener("scroll", this.handleScroll); //监听滚动事件
    },
    methods: {
        studentStatusPost(sfzNum,exam_uuid){
            var time1 = Date.parse(new Date());
            let studentStatusTime = localStorage.getItem("studentStatusTime");
            if( time1 > parseInt(studentStatusTime) + 10 || studentStatusTime == 0){
                studentStatus(sfzNum,exam_uuid).then( (res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    localStorage.setItem("studentStatusTime", time1);

                    if(res.data.renzheng == 0 ){
                        this.$router.replace('/camera');
                    }
                    if(res.data.huanjing == 0){
                        this.$router.replace('/envir');
                    }

                    if(res.data.chengnuoshu == 1){
                        this.$router.replace({path: '/position'});
                    }
                });

            }
        },

        handleScroll(val) {
            console.log(111, val)
        },
        //电脑设备事件
        mouseDown(ev) {
            ev = ev || event;
            ev.preventDefault();
            let obj = {
                x: ev.offsetX,
                y: ev.offsetY
            };
            this.startX = obj.x;
            this.startY = obj.y;
            this.canvasTxt.beginPath();//开始作画
            this.points.push(obj);//记录点
            this.isDown = true;
        },
        //电脑设备事件
        mouseMove(ev) {
            ev = ev || event;
            // ev.preventDefault();
            // if (this.isDown) {
            //     let obj = {
            //         x: ev.offsetX,
            //         y: ev.offsetY
            //     };
            //     this.moveY = obj.y;
            //     this.moveX = obj.x;
            //     this.canvasTxt.moveTo(this.startX, this.startY);//移动画笔
            //     this.canvasTxt.lineTo(obj.x, obj.y);//创建线条
            //     this.canvasTxt.stroke();//画线
            //     this.startY = obj.y;
            //     this.startX = obj.x;
            //     this.points.push(obj);//记录点
            // }
            this.touchMoveFont = true;
        },
        //电脑设备事件
        mouseUp(ev) {
            ev = ev || event;
            ev.preventDefault();
            if (1) {
                let obj = {
                    x: ev.offsetX,
                    y: ev.offsetY
                };
                this.canvasTxt.closePath();//收笔
                this.points.push(obj);//记录点
                this.points.push({x: -1, y: -1});
                this.isDown = false;
            }
        },
        //移动设备事件
        touchStart(ev) {
            ev = ev || event;
            ev.preventDefault();
            // 页面滚动高度
            let scrollHeight = document.documentElement.scrollTop || window.pageYOffset || scrollY;
            if (ev.touches.length == 1) {
                this.isDraw = true; //签名标记
                let obj = {
                    x: ev.targetTouches[0].clientX - this.$refs['promise_sign'].offsetLeft,
                    y: ev.targetTouches[0].clientY - (this.$refs['promise_sign'].offsetTop - scrollHeight)
                };
                this.startX = obj.x;
                this.startY = obj.y;
                this.canvasTxt.beginPath();//开始作画
                this.points.push(obj);//记录点
            }
        },
        //移动设备事件
        touchMove(ev) {
            ev = ev || event;
            // ev.preventDefault();
            // // 页面滚动高度
            // let scrollHeight = document.documentElement.scrollTop || window.pageYOffset || scrollY;
            // if (ev.touches.length == 1) {
            //     let obj = {
            //         x: ev.targetTouches[0].clientX - this.$refs['promise_sign'].offsetLeft,
            //         y: ev.targetTouches[0].clientY - (this.$refs['promise_sign'].offsetTop - scrollHeight)
            //     };
            //     this.moveY = obj.y;
            //     this.moveX = obj.x;
            //     this.canvasTxt.moveTo(this.startX, this.startY);//移动画笔
            //     this.canvasTxt.lineTo(obj.x, obj.y);//创建线条
            //     this.canvasTxt.stroke();//画线
            //     this.startY = obj.y;
            //     this.startX = obj.x;
            //     this.points.push(obj);//记录点
            // }
            this.touchMoveFont = true;
        },
        //移动设备事件
        touchEnd(ev) {
            ev = ev || event;
            ev.preventDefault();
            // 页面滚动高度
            let scrollHeight = document.documentElement.scrollTop || window.pageYOffset || scrollY;
            if (ev.touches.length == 1) {
                let obj = {
                    x: ev.targetTouches[0].clientX - this.$refs['promise_sign'].offsetLeft,
                    y: ev.targetTouches[0].clientY - (this.$refs['promise_sign'].offsetTop - scrollHeight)
                };
                this.canvasTxt.closePath();//收笔
                this.points.push(obj);//记录点
                this.points.push({x: -1, y: -1});//记录点
            }
        },
        //重写
        overwrite() {
            this.canvasTxt.clearRect(
                0,
                0,
                this.$refs.canvasF.width,
                this.$refs.canvasF.height
            );
            this.points = [];
            this.isDraw = false; //签名标记
        },
        // 取消
        onCancel() {
            this.$router.replace('/position');
        },

        handleReset () {
            this.$refs.esign.reset();
            this.touchMoveFont = false;
            this.checked = false;
            // this.$nextTick(()=>{
            //     this.$refs.esign.$el.style.backgroundColor = '#ccc';
            // });
        },
        // 将base64转换为blob
        dataURLtoBlob (dataurl) {
            var arr = dataurl.split(',')
            var mime = arr[0].match(/:(.*?);/)[1]
            var bstr = atob(arr[1])
            var n = bstr.length
            var u8arr = new Uint8Array(n)
            while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
            }
            return new Blob([u8arr], { type: mime })
        },
        // 将blob转换为file
        blobToFile (theBlob, fileName) {
            theBlob.lastModifiedDate = new Date()
            theBlob.name = fileName
            return theBlob
        },

        //确认同意须知
        promiseChecked(){
            if( this.touchMoveFont == false){
                Notify({ type: 'warning', message: '请勿提交空白签名' ,color: '#eee',
                background: '#8b2d87'});
                this.checked = false;
            }
        },
        // 提交
        async onCommit() {
            if (this.submit) {
                this.loadingFile = true;
                this.submit = false;
                if(this.touchMoveFont == false){
                    Notify({ type: 'warning', message: '请勿提交空白签名' ,color: '#eee',
                    background: '#8b2d87'});
                    this.checked = false;
                    this.loadingFile = false;
                }
                this.isHide =false;
                let exam_uuid = localStorage.getItem("monitor-subjectUuId");
                let sfzNum = localStorage.getItem("monitor-zkz");
                studentStatus(sfzNum,exam_uuid).then( (res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    if(res.data.chengnuoshu == 1){
                        this.$router.replace({path: '/monitor'});// 清华附中-民乐 不需要须知/detail baiansen
                    }else{
                        Notify({
                            type:"primary",
                            message: '请稍后',
                            duration: 1000,
                            color: '#eee',
                            background: '#8b2d87'
                        });
                        if(!this.checked){
                            Notify({ type: 'warning', message: '请勾选我自愿遵守本承诺书要求' ,color: '#eee',
                            background: '#8b2d87'});
                            this.submit = true;
                            this.loadingFile = false;
                        }else{

                               html2canvas(this.$refs.promise_content).then(canvas => {
                                let dataURL = canvas.toDataURL("image/png");
                                if (dataURL !== "") {
                                    var blob = this.dataURLtoBlob(dataURL);
                                    const zkz = localStorage.getItem('monitor-zkz');
                                    const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                                    this.resultImg = this.blobToFile(blob, 'sign/' + zkz + '_' + subjectUuid +  '_签名.jpg');
                                    if(this.resultImg){
                                        this.fileOss();
                                     }
                                }else{
                                    Notify({ type: 'warning', message: '保存图片失败' ,color: '#eee',
                                    background: '#8b2d87'});
                                    this.isHide = true;
                                    this.checked = false;
                                    this.submit = true;
                                    this.loadingFile = false;
                                }
                              });
                        }
                    }

                });
            }else{
                Notify({ type: 'warning', message: '请稍后' ,color: '#eee',
                background: '#8b2d87'});
                this.loadingFile = false;
            }
        },
        async fileOss() {

            const zkz = localStorage.getItem('monitor-zkz');
            const subjectUuid = localStorage.getItem("monitor-subjectUuId");
            let fileName = this.resultImg.name;
            let {data} = await getSignature();
            let onUploadProgress = (progressEvent => {
                let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                if (complete > 90) {
                    complete = 90;
                }
                this.targetRate = complete;
            });

            let uploadData = await fileUpload(data, this.resultImg,  fileName , onUploadProgress);

            if (uploadData.status == 200 && uploadData.data && uploadData.data.Status === 'OK') {
                this.isHide = true;
                this.checked = false;
                let {data: res} = await promise(zkz,subjectUuid,uploadData.data.url);
                if(res.code == 200){
                    this.$router.replace('/monitor');// 清华附中-民乐 不需要须知/detail baiansen
                }else{
                    Notify({ type: 'warning', message: res.result ,color: '#eee',
                    background: '#8b2d87'});
                    this.isHide = true;
                    this.submit = true;
                    this.loadingFile = false;
                }
            }else{
                Notify({ type: 'warning', message: res.result ,color: '#eee',
                background: '#8b2d87'});
                this.isHide = true;
                this.checked = false;
                this.submit = true;
                this.loadingFile = false;
            }


        },
    },
    destroyed() {
    },
};
